@import "../../scss/color.scss";

#resumeArea {
  margin: 0.5rem 0rem;
  height: 20rem;
  background-color: transparent;
  iframe {
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;
    opacity: 0;
    visibility: hidden;
    transition: opacity $transition-1, visibility $transition-2;
  }
}
