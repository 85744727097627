/* gradient */
/* solid */
:export {
  vergasgold: hsl(45, 54%, 58%);
}

#contactMe-start {
  margin: 0.5rem 0rem;
}
#contactMe-start #contactMe-map-iframe-area {
  width: 100%;
  height: 20rem;
}
#contactMe-start #contactMe-map-iframe-area iframe {
  display: block;
  width: 100%;
  height: 20rem;
  margin: 1rem 0rem;
  border: none;
  border-radius: 0.8rem;
  box-shadow: 0 16px 30px hsla(0, 0%, 0%, 0.25);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.25s ease, visibility 0.5s ease-in-out;
}/*# sourceMappingURL=contactMe.css.map */