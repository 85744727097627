/* gradient */
/* solid */
:export {
  vergasgold: hsl(45, 54%, 58%);
}

* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
}

*,
::before,
::after {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

html {
  overflow-x: hidden;
  font-size: 18px !important; /* Base font size */
  width: 100vw;
}
html body {
  overflow-x: hidden;
  background-color: rgb(18, 18, 18);
  color: #fafafa;
  width: 100%;
}
html body #root {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
html body #root #main-start-1 {
  width: 80%;
  height: 100%;
  margin: 3rem 0rem;
  position: relative;
}
html body #root #main-start-1 #main-start-2 {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2rem;
}

@media (max-width: 560px) {
  #main-start-1 {
    width: 100% !important;
  }
  #main-start-1 #main-start-2 {
    width: 95% !important;
    display: block !important;
    padding: 0.7rem 0.5rem 3rem !important;
  }
}
@media (min-width: 1024px) {
  #main-start-1 {
    max-width: 1200px;
    width: 1200px;
  }
}
a {
  text-decoration: none;
}

@media (max-width: 1024px) {
  html {
    font-size: 18px;
    width: 100vw;
  }
  html body {
    background-color: rgb(18, 18, 18);
    color: #fafafa;
    width: 100%;
  }
  html body #root {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  html body #root #main-start-1 {
    width: 90%;
    height: 100%;
    margin: 3rem 0rem;
    position: relative;
  }
  html body #root #main-start-1 #main-start-2 {
    width: 100% !important;
    flex-direction: column !important;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 1rem;
  }
}
@media (max-width: 768px) {
  #main-start-1 {
    position: unset !important;
    margin: 1rem 0rem 2rem !important;
  }
  #main-start-1 #main-start-2 {
    position: unset !important;
  }
}/*# sourceMappingURL=app.css.map */