/* gradient */
/* solid */
:export {
  vergasgold: hsl(45, 54%, 58%);
}

#right-part-1 {
  position: relative;
  background-color: hsl(240, 2%, 12%);
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  margin-bottom: 0px;
  z-index: 1;
  border: 1px solid hsl(0, 0%, 22%);
  border-radius: 1rem;
}
#right-part-1 #right-part-2 {
  min-height: 20rem;
  display: flex;
  flex-direction: column;
  padding: 2rem 1.5rem;
}
#right-part-1 #right-part-2 #right-area-heading {
  font-size: 2rem;
}
#right-part-1 #right-part-2 #right-area-heading::after {
  margin: 0.7rem 0rem;
  border-radius: 1rem;
  display: block;
  width: 10%;
  height: 5px;
  content: " ";
  background-color: hsl(45, 100%, 72%);
}
#right-part-1 #right-part-2 #right-area-about {
  margin: 0.5rem 0rem;
  font-size: 1rem;
  line-height: 1.5rem;
  color: hsla(0, 0%, 84%, 0.7);
  text-align: justify;
}

#skill-section-start {
  width: 100%;
}
#skill-section-start #skill-section-skill-tabs-area {
  width: 100%;
  margin: 1rem 0rem 0rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
#skill-section-start #skill-section-skill-tabs-area .skill-section-skill-tabs {
  position: relative;
  background: linear-gradient(to bottom right, hsl(0, 0%, 25%) 0%, hsla(0, 0%, 25%, 0) 50%);
  padding: 20px;
  border-radius: 14px;
  box-shadow: 0 16px 30px hsla(0, 0%, 0%, 0.25);
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  height: 8rem;
}
#skill-section-start #skill-section-skill-tabs-area .skill-section-skill-tabs::before {
  content: "";
  position: absolute;
  inset: 1px;
  background: linear-gradient(to bottom right, hsla(240, 1%, 18%, 0.251) 0%, hsla(240, 2%, 11%, 0) 100%), hsl(240, 2%, 13%);
  border-radius: inherit;
  z-index: -1;
}
#skill-section-start #skill-section-skill-tabs-area .skill-section-skill-tabs .skill-section-skill-tab-icon {
  font-size: 2rem;
  color: hsl(45, 100%, 72%);
}
#skill-section-start #skill-section-skill-tabs-area .skill-section-skill-tabs .skill-section-skill-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
#skill-section-start #skill-section-skill-tabs-area .skill-section-skill-tabs .skill-section-skill-text h3 {
  width: 100%;
  font-size: 1.2rem;
  font-weight: 500;
  color: hsl(0, 0%, 98%);
}
#skill-section-start #skill-section-skill-tabs-area .skill-section-skill-tabs .skill-section-skill-text p {
  width: 100%;
  font-size: 0.9rem;
  line-height: 1rem;
  font-weight: 300;
  color: hsla(0, 0%, 84%, 0.7);
  text-align: justify;
}

@media (max-width: 768px) {
  #right-part-1 {
    position: unset;
  }
  #skill-section-skill-tabs-area {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}/*# sourceMappingURL=rightPart.css.map */