/* gradient */
/* solid */
:export {
  vergasgold: hsl(45, 54%, 58%);
}

#contactMeFormArea {
  display: block;
  position: relative;
  margin: 1.4rem 0rem 0rem;
  width: 100%;
  height: 100%;
  background-color: transparent;
}
#contactMeFormArea #contactMeFormAreaHeadingArea {
  margin: 1rem 0rem;
}
#contactMeFormArea #contactMeFormAreaHeadingArea h1 {
  font-size: 1.5rem;
}
#contactMeFormArea #contactMeFormAreaHeadingArea #contactMeFormAreaStart {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.contactMeFormAreaSections {
  padding: 0.5rem 0rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  gap: 3rem;
}
.contactMeFormAreaSections div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.contactMeFormAreaSections div input {
  margin: 0rem 0rem 0.5rem;
  word-spacing: 0.1rem;
  font-weight: 400;
  width: 100%;
  border: none;
  background-color: transparent;
  font-size: 0.8rem;
  padding: 0.7rem 1rem;
  outline: none;
  color: hsla(0, 0%, 84%, 0.7);
  border: 1px solid hsla(0, 0%, 84%, 0.7);
  border-radius: 0.5rem;
}
.contactMeFormAreaSections div input:focus {
  border-color: hsl(45, 100%, 72%);
}
.contactMeFormAreaSections div input:not(:-moz-placeholder-shown) {
  color: hsl(0, 0%, 98%);
}
.contactMeFormAreaSections div input:not(:placeholder-shown) {
  color: hsl(0, 0%, 98%);
}

#contactMeFormArea-secondSection {
  width: 100%;
}
#contactMeFormArea-secondSection textarea {
  width: 100%;
  height: 5rem;
  resize: vertical;
  text-align: justify;
  margin: 0rem 0rem 0.5rem;
  word-spacing: 0.1rem;
  font-weight: 400;
  width: 100%;
  border: none;
  background-color: transparent;
  font-size: 0.8rem;
  padding: 0.7rem 1rem;
  outline: none;
  color: hsla(0, 0%, 84%, 0.7);
  border: 1px solid hsla(0, 0%, 84%, 0.7);
  border-radius: 0.5rem;
}
#contactMeFormArea-secondSection textarea:focus {
  border-color: hsl(45, 100%, 72%);
}
#contactMeFormArea-secondSection textarea:not(:-moz-placeholder-shown) {
  color: hsl(0, 0%, 98%);
}
#contactMeFormArea-secondSection textarea:not(:placeholder-shown) {
  color: hsl(0, 0%, 98%);
}

#contactMeFormArea-thirdSection {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
#contactMeFormArea-thirdSection #contactMeFormArea-thirdSection-submit-button-area {
  display: block;
  position: relative;
  width: 30%;
  cursor: pointer;
  color: hsl(45, 100%, 72%);
  padding: 0.7rem 2rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: 0 16px 40px hsla(0, 0%, 0%, 0.25);
  transition: 0.25s ease;
  background: linear-gradient(to bottom right, hsl(0, 0%, 25%) 0%, hsla(0, 0%, 25%, 0) 50%);
  transform: scale 0.5s ease-in-out;
  outline: none;
  border: none;
}
#contactMeFormArea-thirdSection #contactMeFormArea-thirdSection-submit-button-area:active {
  scale: 0.9 0.9;
}
#contactMeFormArea-thirdSection #contactMeFormArea-thirdSection-submit-button-area p {
  cursor: pointer;
  text-transform: capitalize;
  color: hsl(45, 100%, 72%);
  text-align: center;
  background-color: transparent;
  font-size: 0.8rem;
  padding: 0rem 0rem 0rem 1rem;
}
#contactMeFormArea-thirdSection #contactMeFormArea-thirdSection-submit-button-area p::before {
  content: "";
  position: absolute;
  inset: 1px;
  background: linear-gradient(to bottom right, hsla(240, 1%, 18%, 0.251) 0%, hsla(240, 2%, 11%, 0) 100%), hsl(240, 2%, 13%);
  border-radius: inherit;
  z-index: -1;
  transition: 0.25s ease;
}

.errorMsg {
  color: hsl(0, 43%, 51%);
  font-size: 0.8rem;
}

@media (max-width: 1024px) {
  #contactMeFormArea-thirdSection #contactMeFormArea-thirdSection-submit-button-area {
    width: 40%;
  }
}
@media (max-width: 768px) {
  #contactMeFormArea-firstSection {
    flex-direction: column;
    gap: 1rem;
  }
  #contactMeFormArea-thirdSection #contactMeFormArea-thirdSection-submit-button-area {
    width: 60%;
  }
}
@media (max-width: 450px) {
  #contactMeFormArea-thirdSection #contactMeFormArea-thirdSection-submit-button-area {
    width: 100%;
  }
}/*# sourceMappingURL=contactMeForm.css.map */