@import "../scss/color.scss";

#selected-project-data {
  z-index: 4;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.3);
  width: 100vw;
  height: 100vh;
  top: 0;
  display: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  #selected-project-cross {
    z-index: 20;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    margin: 1rem;
    font-size: 2rem;
    transition: scale $transition-1;
    color: $orange-yellow-crayola;
    &:hover {
      scale: 1.1;
    }
  }
  #selected-project {
    z-index: 10;
    opacity: 1;
    display: block;
    border-radius: 1rem;
    padding: 1rem;
    background-color: $jet;
    width: 30%;
    #selected-project-img {
      text-align: center;
      padding: 0rem 2rem;
      img {
        object-fit: cover;
        width: 80%;
        height: 12rem;
      }
    }
    #selected-project-heading {
      margin: 1rem 0rem;
      h1 {
        font-size: 1.1rem;
        text-align: justify;
      }
    }
    #selected-project-desc {
      margin: 1rem 0rem;
      p {
        font-size: 0.9rem;
        color: $light-gray;
        line-height: 1.3rem;
        text-align: justify;
      }
    }
    #selected-project-actionButton {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      a {
        margin: 0rem 0.5rem;
        font-size: 1.8rem;
        color: $orange-yellow-crayola;
        cursor: pointer;
        transition: scale $transition-1;
        &:hover {
          scale: 1.1;
        }
      }
    }
  }
}

.whenProjectSelected {
  overflow: hidden;
}

@media (max-width: 1024px) {
  #selected-project {
    width: 55% !important;
  }
}

@media (max-width: 768px) {
  #selected-project {
    width: 80% !important;
  }
}
