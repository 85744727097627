@import "../../scss/color.scss";

#sending-message-animation-area-start {
  background-color: $onyx;
  border-radius: 0.5rem;
  width: 100%;
  height: 100%;
  position: absolute;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 3;
}
.sending-animation {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.sending-animation span {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  background-color: $orange-yellow-crayola;
  border-radius: 50%;
  animation: bounce 1.2s infinite ease-in-out;
}
.sending-animation span:nth-child(2) {
  animation-delay: -0.4s;
}
.sending-animation span:nth-child(3) {
  animation-delay: -0.8s;
}
@keyframes bounce {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
.message {
  width: 100%;
  text-align: center;
  font-size: 1.5em;
  color: $orange-yellow-crayola;
}
.success {
  color: green;
}
.failure {
  color: red;
}
