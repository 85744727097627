/* gradient */
/* solid */
:export {
  vergasgold: hsl(45, 54%, 58%);
}

#projects-area-start {
  width: 100%;
}
#projects-area-start #projects-area-nav-tabs {
  margin: 1rem 0rem 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1.5rem;
}
#projects-area-start #projects-area-nav-tabs p {
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.25s ease;
}
#projects-area-start #projects-area-nav-tabs p:hover {
  color: hsla(0, 0%, 84%, 0.7);
}
#projects-area-start #projects-area-nav-tabs-2 {
  margin: 1rem 0rem 1.5rem;
  position: relative;
  display: none;
  width: 100%;
}
#projects-area-start #projects-area-nav-tabs-2 #project-area-nav-bar-toggle-button-area {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  background-color: hsl(240, 2%, 12%);
  color: hsl(0, 0%, 84%);
  outline: none;
  border: 1px solid hsl(0, 0%, 22%);
  padding: 0.5rem 0.7rem;
  border-radius: 1rem;
  cursor: pointer;
}
#projects-area-start #projects-area-nav-tabs-2 #project-area-nav-bar-toggle-button-area button {
  font-weight: 100 !important;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font: inherit;
  color: inherit;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  display: inline-block;
}
#projects-area-start #projects-area-nav-tabs-2 .dropdown-menu {
  opacity: 0;
  margin-top: 0.3rem;
  display: block;
  position: absolute;
  z-index: -5;
  background-color: inherit;
  background-color: hsl(240, 2%, 12%);
  color: hsl(0, 0%, 84%);
  width: 100%;
  border: 1px solid hsl(0, 0%, 22%);
  border-radius: 1rem;
  padding: 1rem;
}
#projects-area-start #projects-area-nav-tabs-2 .dropdown-menu p {
  cursor: pointer;
  padding: 0.5rem 0.7rem;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.25s ease;
}
#projects-area-start #projects-area-nav-tabs-2 .dropdown-menu p:hover {
  color: hsla(0, 0%, 84%, 0.7);
}
#projects-area-start #projects-area-nav-tabs-2 .show {
  opacity: 1;
  z-index: 5;
}
#projects-area-start #projects {
  position: relative;
  width: 100%;
  display: grid;
  gap: 2rem 2rem;
  grid-template-columns: repeat(3, 1fr);
}
#projects-area-start #projects .project-data-start {
  transition: scale 0.25s ease;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  cursor: pointer;
}
#projects-area-start #projects .project-data-start:hover .project-data-image-area {
  transform: scale(1.05);
  background-color: hsla(0, 0%, 84%, 0.7);
}
#projects-area-start #projects .project-data-start:hover .project-data-image-area .project-data-image-eye-area {
  display: flex;
}
#projects-area-start #projects .project-data-start .project-data-image-area {
  transition: transform 0.25s ease;
  position: relative;
  width: 100%;
  border-radius: 1rem;
}
#projects-area-start #projects .project-data-start .project-data-image-area .project-data-image-eye-area {
  position: absolute;
  width: 100%;
  height: 100%;
  display: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  opacity: 0.9;
  background-color: hsl(240, 2%, 12%);
  z-index: 2;
}
#projects-area-start #projects .project-data-start .project-data-image-area .project-data-image-eye-area .project-data-image-eye {
  padding: 0.5rem;
  border-radius: 1rem;
  color: hsl(45, 100%, 72%);
  background-color: hsl(0, 0%, 7%);
  font-size: 3rem;
}
#projects-area-start #projects .project-data-start .project-data-image-area img {
  border-radius: 1rem;
  width: inherit;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  height: 10rem;
}
#projects-area-start #projects .project-data-start .project-data-image-area::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 1;
  transition: 0.25s ease;
}
#projects-area-start #projects .project-data-start .project-data-description-area {
  margin-top: 1rem;
}
#projects-area-start #projects .project-data-start .project-data-description-area h3 {
  font-size: 1rem;
  font-weight: 100;
  color: hsl(0, 0%, 98%);
}
#projects-area-start #projects .project-data-start .project-data-description-area p {
  color: hsla(0, 0%, 84%, 0.7);
  font-size: 0.8rem;
  margin: 0.4rem 0rem;
  text-transform: capitalize;
}

.project-area-nav-tab-item-selected {
  color: hsl(45, 100%, 72%);
}

@media (max-width: 1024px) {
  #projects {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}
@media (max-width: 768px) {
  #projects {
    grid-template-columns: repeat(1, 1fr) !important;
  }
  #projects-area-nav-tabs {
    display: none !important;
  }
  #projects-area-nav-tabs-2 {
    display: block !important;
  }
}/*# sourceMappingURL=projects.css.map */